@import "./Variables";

.headerContainer {
  background-color: $black;
}

.linkText {
  position: relative;
  z-index: 1;
}

.header_mobile {
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  position: fixed;
  width: 100%;
  padding: 1rem;
  background: $black;
  box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
  border: none;
  top: 0;
  z-index: 3;

  .closeBtn {
    background: $black;
    width: 40px;
    border-radius: 50%;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    span {
      display: block;
      width: 20px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: $lila;
      border-radius: 3px;
    }

    span:nth-child(2) {
      width: 25px;
    }

    span:last-child {
      margin-bottom: 0;
    }
  }
}

.mobileMenu_menuItemLabel {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  border-bottom: 1px solid white;
  padding: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.mobileMenu_listElement_link {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $texthover;
  }
}

.menuItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menuList {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.menuList_listItem {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1060px ){
  .header_mobile {
    display: flex;
  }
}

.buttonContainer {
  background-image: url("./images/Verlauf-Highlight.svg");
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: white;
  height: 100%;
  padding: 1rem;
  margin-right: 1rem;
}

.profileButton{

  height: 3rem;
  display: flex;
  align-items: center;
  width: 1.5rem;
  margin: 0 1rem 0 0 !important;
  padding: 0 !important;

  &:hover {
    background-image: url("./images/Verlauf-Highlight.svg");
    background-position-x: 95% ;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.loginButton {
  letter-spacing: 2px;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: $white;
  background: none;
  border: none;

  &:hover {
    color: white;
  }
}


.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons {
  height: 1.5rem;
}

.btn_primary {
  background: $primary;
  color: $white;
  font-weight: 700;
  border: none;
  border-radius: 0.25rem;
  margin: auto;
  transition: 0.5s;
  font-size: 18px;

  &:hover {
    background: #8599d7;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
