@import "./Variables";

@mixin head {
  padding: 1rem 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: $white;
  display: flex;
  align-items: center;
  background: $lila;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.Registration {
  margin-top: 5rem;
  background: $black;
  height: calc(100vh - 5rem);
  color: $white;
}

.header {
  background: $lila;
  height: auto;
  padding: 2rem 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_title {
  text-align: center;
  font-size: 2.5rem;
  color: $white;
}

.subHeadline{
  color: $white;
}

.black_line {
  height: 100%;
  background: $secondary;
  border-radius: 10px;
  width: 4px;
}

.btn_primary {
  background: $lila;
  color: $white;
  border: none;
  height: 45px;
  border-radius: 10px;
  width: 100%;
  margin: auto;

  &:hover {
    opacity: .7;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.link {
  color: $white;

  & > svg {
    margin-right: .5rem;
    fill: $lila;
  }
}

.backbutton {
  height: 60px;
  width: 200px;
  background: $lila;
  border-radius: 12px;
  margin-top: 4px;
  margin-left: 4px;
  color: $white;
}
.whatareyoubuttons {
  background: $lila;
  color: $white;
  border: none;
  height: 60px;
  border-radius: 10px;
  width: 50%;
  margin: auto;

  &:disabled {
    cursor: not-allowed;
  }
}

.card {
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,1);

  .head {
    @include head;
    font-size: 25px;
    justify-content: center;
    flex-direction: column;
  }

  .cursor_pointer {
    width: 100%;
    cursor: pointer;
  }

  .body {
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    input {
      margin-top: 1rem;
      border-radius: 25px;
      width: 100%;
      height: 45px;
      border: 2px solid #dbdbdb;
      outline: none;
      padding: 0.25rem 0.5rem;
      background: $black;
    }
  }
}

@mixin line_styling {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background: $gray;
  top: 50%;
  position: absolute;
}

.head2 {
  font-size: 20px;
  @include head;
  justify-content: space-evenly;
  cursor: pointer;
}

.title_text {
  color: $white;
  font-size: 20px;
  text-align: center;
}

.login_section_image {
  border-radius: 10px;
  width: 450px;
  height: 100%;
}

.eye_icon {
  margin-left: -30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .card {
    .cursor_pointer {
      width: 70%;
    }
  }

  .login_section_image {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .login_section_image {
    display: none;
  }
}
