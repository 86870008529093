@import "../../../components/Styles/Variables";

.ProfileSocialMedia {

  height: 100%;
  width: 100%;

  &.edit {
    background: rgb(30,29,29);
  }

  &__head {

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    &__label {
      color: $white;
      opacity: .4;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &__profiles {

    display: flex;
    flex-direction: column;

    &__wrapper {
      width: 100%;
      margin-top: .5rem;
      height: calc(50px - .5rem);
      &:first-of-type {
        margin-top: 0;
        height: 50px;
      }
    }

    &__chip {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      overflow: hidden;
    }

    &__button {
      background: none;
      border: none;

      img {
        height: 2rem;
        width: 2rem;
      }

      & > svg {
        fill: $white;
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    &__link {
      text-decoration: none;
      color: $white;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icon {
      height: 2rem;
      width: 2rem;
      margin-right: .5rem;
    }
  }

}