@import "./Variables";

.ProfileInbox {
  background: $black;
  margin-top: 5rem;
  min-height: 100%;
}

.bg_secondary {
  height: 3.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: $white;
  font-size: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: linear-gradient(-45deg, $primary, #23a6d5, $secondary);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.wrap_message {
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
}

.bg_black {
  background: $white;
  border-radius: 10px;
  box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
  border: 1px solid #eaeaea;
  margin: auto;
}
.headerMargin {
  margin-top: 6rem;
}
.input {
  border-radius: 10px;
  width: 50%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
}

.input_message {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
  background: $black;
  color: $white;
}

.userCard_scroll_inbox {

  height: 600px;
  overflow-y: auto;
}
.seenPointContainer {
  width: 10px;
  height: 10px;
  border-radius: 60px;
  background-color: $primary;
}
.send_message_input {
  padding: 0.7rem 0.5rem;
  display: flex;
  align-items: center;
}

.userCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 1rem 0.5rem;
  margin: 15px 0;
  cursor: pointer;
  min-height: 115px;

  .userCard_profile {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid $white;
    object-fit: cover;
  }

  .userCard_active {
    background: #66c60a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 15%;
    border: 2px solid $white;
  }

  .userCard_name {
    color: $white;
    font-size: 25px;
    font-weight: 600;
    text-overflow: ellipsis;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
  }

  .userCard_message {
    color: gray;
    font-size: 16px;
  }

  .userCard_badge {
    background: $primary;
    font-size: 16px;
    font-weight: 500;
    color: $white;
    border-radius: 5px;
    padding: 0.25rem;
    text-transform: uppercase;
    height: 20px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.userCard_profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid $white;
  object-fit: cover;
}

.userCard_sent_messages {
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.userCard_received_messages {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;
}

.profilePicInHeader {
  height: 60px;
  width: 100%;
  position: sticky;
  top: -90px;
  display: flex;
  justify-content: center;
  background-color: #66c60a;
}
.userCard_messages_time {
  margin-top: 0.5rem;
  color: $white;
  font-size: 10px;
}

.headerNameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.headerName {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  position: relative;
  margin: 0 0 0 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.topHeader {
  height: 5rem;
  width: 100%;
  position: sticky;
  opacity: 1;
  top: 0;
  padding: 0;
  background-color: $black;
  display: flex;
  flex-direction: row;
  border: 2px solid $white;

  @media (min-width: 990px) {
    display: none;
  }
}

.backArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  margin-right: .5rem;
  border-right: 2px solid $white;

  & > svg {
    width: 3rem;
    height: 3rem;
  }
}

.profileLink {
  display: flex;
  align-items: center;
}

.userCard_messages {
  word-break: break-all;
  text-align: end;
  font-size: 14px;
  margin-top: 0.5rem;
  color: black;
  background: $gray;
  padding: 1rem;
  width: fit-content;
  border-radius: 10px;
}

.responsive_image {
  margin-top: 5rem;
  width: 100%;
  height: 60px;
}

@media (max-width: 1200px) {
  .input {
    width: 100%;
  }

  .responsive_image {
    height: 6rem;
  }
}

@media (max-width: 769px) {
  .input_message {
    width: calc(100vw - 34px);
  }

  .userCard_name {
    font-size: 20px !important;
  }
}

@media (max-width: 450px) {
  .userCard_messages {
    padding: 0.5rem;
  }

  .userCard_profile {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 767px) and (max-width: 768px) {
  .send_message_input {
    width: 98%;
  }
}

@media (max-width: 1000px) {
  .send_message_input {
    width: 97%;
  }
}

.send_message_icon {
  cursor: pointer;
  margin: 0 0 0 -55px;
}
