@import './Variables';


.Toastify__toast-theme--light {
  background: transparent;
  border-radius: 10px !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  height: fit-content;

  &.Toastify__toast--success {
    border-left: 5px solid $lila;
    background: $white;

    .Toastify__toast-icon {
      svg {
        animation: flip 2s ease-in;
        fill: $lila;
      }
    }
  }

  &.Toastify__toast--warning {
    border-left: 5px solid $orange;
    background: $white;

    .Toastify__toast-icon {
      svg {
        animation: spin 1s ease-in-out;
        fill: $orange;
      }
    }
  }

  &.Toastify__toast--error {
    border-left: 5px solid $danger;
    background: $white;

    .Toastify__toast-icon {
      svg {
        animation: spin 1s ease-in-out;
        fill: $danger;
      }
    }
  }

  .Toastify__progress-bar-theme--light {
    background-color: $gray !important;
    height: 3px;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  50% {
    transform: perspective(400px) rotateY(360deg) scale(.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}
