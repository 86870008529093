:root {
    --primary: #5b7ada;
    --secondary: #461399;
    --gray: #f5f5f5;
    --white: #fff;
    --danger: #b0010f;
    --warning: #c1c1c1;
    --orange: #ffb428;
    --offered: #421776;
    --green: #66c60a;
    --finished: #3b7200ff;
    --success: #00d25b;
    --black: rgb(16, 16, 16);
    --lila:  rgb(97,10,206);
    /*--menu-width: 50em; !* Width of menu *!*/
    /*--items: 3; !* Number of items you have *!*/
    /*--item-width: calc(var(--menu-width) / var(--items));*/
}

@font-face {
    font-family: "Helvetica";
    src: local("Helvetica"), url("./fonts/Helvetica/Helvetica.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "HyperwaveOne";
    src: url("./fonts/webFonts/HyperwaveOne/font.woff2") format("woff2"),
        url("./fonts/webFonts/HyperwaveOne/font.woff") format("woff");
}

@font-face {
    font-family: "HyperwaveOne";
    src: url("./fonts/webFonts/HyperwaveOne/font.woff2") format("woff2"),
        url("./fonts/webFonts/HyperwaveOne/font.woff") format("woff");
}
/* @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    src: url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    src: url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "bold";
    font-weight: bold;
    font-style: normal;
    src: url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
/* @font-face {
    font-family: "Roboto";
    font-style: normal;
    src: url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
} */

body {
    margin: 0;
    font-family: "Helvetica", sans-serif !important;
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: overlay;
    height: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h3 {
    margin: 1rem 0;
    font-size: 50px;
    font-weight: 700;
    color: var(--white);
}

.c-white {
    color: var(--white);
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-corner {
    background: var(--black);
}

::-webkit-scrollbar-track {
    background: var(--black);
}

::-webkit-scrollbar-thumb {
    background: var(--lila);
}

.static-page {
    /* margin-top depending on height of header */
    margin-top: 5rem;
    background: var(--black);
}

.headline-title {
    font-family: HyperwaveOne;
    font-size:150px;
    color: var(--white);
}

@media screen and (max-width: 1024px){
    .headline-title {
        font-size: 100px;
    }
}

@media screen and (max-width: 768px){
    .headline-title {
        font-size: 75px;
    }
}

@media screen and (max-width: 480px){
    .headline-title {
        font-size: 50px;
    }
}

.static-page > * {
    color: var(--white);
}

.cursor_pointer {
    cursor: pointer;
}

.text_wrap {
    word-break: break-all;
}

.text_decoration_none {
    text-decoration: none !important;
}

.text_decoration_none:hover {
    color: rgba(91, 122, 218, 0.65) !important;
}

.active_tab_child:hover {
    color: var(--primary);
    font-width: 700;
}

.slide {
    margin-left: 20px !important;
}

.carousel .slide img {
    height: 248px;
    width: 276px;
}

.carousel .slider-wrapper {
    min-height: 300px;
}

.no-style-button {
    background: transparent;
    border: none;
    padding: 0;
}

.carousel .control-dots .dot {
    background: var(--primary) !important;
}

.carousel.carousel-slider {
    margin: 0 auto !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.responsive-input {
    width: 100%;
}

.MuiSelect-select, #react-select-3-placeholder {
    text-align: center;
}

.pb-6 {
    padding-bottom: 4rem;
}
.p-FieldLabel {
    color: var(--white);
}
.searchFilterBox {
    background: var(--primary);
    width: fit-content;
    padding: 0.5rem;
    border-radius: 10px;
}

.searchFilterBox:not(:first-child) {
    margin-left: 1rem;
}

.responsive-user-info {
    text-overflow: ellipsis;
    width: 130px;
    white-space: nowrap;
    text-align: end;
    overflow: hidden;
}

.user-location-responsive {
    text-overflow: ellipsis;
    width: 400px;
    white-space: nowrap;
    text-align: end;
    overflow: hidden;
}

.pagination .paginationWrapper {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
}

.pagination .separator {
    width: 1rem;
    margin-top: 0.3rem;
}

.pagination .pageItem {
    height: 2.7rem;
    width: 2.7rem;
    margin: 0 0.25rem;
    border-radius: 25px;
    font-weight: 600;
    background-color: var(--black);
    color: var(--white);
    border: 2px solid var(--white);
    font-size: 12px;
    padding: 0px;
}

.pagination .pageItem:hover {
    background-color: var(--lila);
    color: var(--white);
}

.pageItem.active {
    background: var(--lila);
}

.pagination .pageItem:focus {
    outline: 0;
}

.issue-table-admin {
    height: 50rem;
    overflow: auto;
}

.css-b62m3t-container:focus-visible {
    outline: none !important;
}

#location .css-1s2u09g-control {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
}

.css-1pahdxg-control {

    border: 2px solid #dbdbdb !important;
    box-shadow: none !important;
}

.admin_issue_icon {
    padding: 0.25rem;
    border-radius: 0.25rem;
    background: var(--success);
}

.height-fit-content {
    height: fit-content;
}

.css-319lph-ValueContainer:focus-visible:hover {
    outline: none !important;
}

.css-1pahdxg-control:hover {
    border-color: unset !important;
    box-shadow: 0 0 0 1px var(--primary);
}

@media (min-width: 1025px) {
    .text-md-black {
        color: white !important;
    }
}

@media (max-width: 768px) {
    .w-md-0 {
        width: 100%;
    }

    .overflow-md-normal {
        overflow: scroll;
    }

    .responsive-table {
        overflow: scroll;
    }

    .custom-margin {
        margin-top: 0.5rem !important;
    }
}

@media (min-width: 769px) {
    .responsive-input {
        width: 30% !important;
    }
}

.card {
    background-color: white;
    padding: 10px 20px 11px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.password-not-valid {
    color: #6EC600;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.forgot-password {
    cursor: pointer;
}

.forgot-password:hover {
    color: rgba(91, 122, 218, 0.65) !important;
}

.forgot-input {
    border-radius: 10px;
    width: 100%;
    height: auto;
    border: 2px solid #dbdbdb;
    outline: none;
    padding: 0.25rem 0.5rem;
}

.forgot-container {
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-button {
    background: var(--primary);
    border-radius: 0.25rem;
    border: none;
    color: #fff;
    font-weight: 600;
    padding: 0.4rem 1.5rem;
}

.forgot-button:hover {
    background: rgba(91, 122, 218, 0.65);
}

.forgot-button:disabled {
    cursor: not-allowed;
}

.pay-button {
    padding: 0.7rem 2rem;
    width: 100%;
    margin: 1rem 0;
    color: white;
    font-weight: bold;
    font-size: medium;
    background-color: #556cd6;
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    transition: box-shadow 500ms;
    cursor: pointer;
}

.scroll-auto {
    max-height: 500px;
    overflow: auto;
}

.pay-button:disabled {
    background-color: #afafaf;
    box-shadow: none;
    cursor: default;
}

.pay-button:disabled:hover {
    box-shadow: none;
}

.pay-button:hover {
    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

/* override header for big screens */

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.jWpUJH {
    bottom: -20px !important;
}

.fw-600 {
    font-weight: 600;
}

.header-mobile svg {
    fill: var(--primary);
}

.header-mobile .item {
    font-weight: 600;
    color: var(--primary);
}

.header-mobile .col-6 {
    margin-bottom: 1rem;
}

.header-mobile p.opacity-75 {
    margin-bottom: 0.5rem !important;
}

.header-mobile .item a {
    text-decoration: none;
    color: unset;
}

.modal-header.d-flex.justify-content-end svg {
    fill: var(--white);
}

.modal-content {
    background-color: unset !important;
    background: var(--lila) !important;
}

.modal-content > * {
    color: var(--white);
}

.blur_image {
    background-image: url("./success_payment.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg_image_website {
    background: no-repeat url("./image.jpg") center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}

.w-95 {
    width: 100%;
}

.edit-icon-button {
    background: none;
    border: none;
}

.edit-icon-button > img {
    height: 2rem;
    width: 2rem;
}

.edit-icon-button > svg {
    fill: var(--white);
    height: 1.5rem;
    width: 1.5rem;
}

.custom-width-button {
    width: 25% !important;
}

@media (max-width: 768px) {
    .bg_image_website {
        height: 100vh;
    }

    .w-95 {
        width: 95%;
    }

    .custom-width-button {
        width: 75% !important;
    }
}

.success_modal {
    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
    border: 1px solid #eaeaea;
    background: #fff;
    border-radius: 0.25rem;
    height: fit-content;
    width: 40%;
}

.success_modal svg {
    margin-bottom: 1rem;
}

.payment-card-select {
    padding: 1rem;
    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
    border-left: 7px solid var(--gray);
    background: var(--lila);
    border-radius: 0.25rem;
    transition: 0.5s;
    color: var(--white);
}

.payment-card-select h4 {
    text-transform: uppercase;
}

.payment-card-select:hover {
    cursor: pointer;
    border-left-color: var(--primary);
}

.payment-card-select.active-tab {
    border-left-color: var(--primary);
    transform: translatex(2%);
}

.payment-quantity {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    font-weight: 600;
}

.payment-quantity svg {
    margin-left: 0.5rem;
    fill: var(--white);
}

.payment-description {
    width: 90%;
}

/* Header for big screens */

header {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
    border-top: none;
    border-left: none;
    border-right: none;
    position: fixed;
    height: 5rem;
    width: 100%;
    z-index: 999;
}

@media screen and (max-width: 1060px) {
    header {
        display: none;
    }
}

header > * {
    margin-right: 1rem;
}

.nav-bar {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.nav__links {
    display: flex;
    list-style: none;
    color: var(--white);
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    height: 100%;
}

.nav--item:hover {
    padding-bottom: 3.5rem;
    border-bottom: 3px solid var(--primary);
    color: var(--primary);
}

.nav--link {
    padding: 0rem 1rem;
    margin: 0 1rem;
    transition: opacity 100ms linear;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    height: 100%;
    align-items: center;
}

.nav--link:hover .label-box{
    width: 100%;
    transition: width .25s linear;
}

.label-box {
    position: absolute;
    top: 20%;
    background: red;
    width: 0%;
    height: 50%;
    z-index: 0;
}

.nav--link .label-container .menu-label {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    color: white;
}


.nav--link .label-container .menu-label:hover {
    color: white;
}

.arrow {
    width: 20px;
    height: 10px;
    position: relative;
    transform: translateX(-4px);
}

.line {
    position: absolute;
    top: 46%;
    right: 0;
    height: 2px;
    width: 7px;
    background-color: var(--primary);
    transform-origin: 100% 50%;
}

.arrow--sideUp {
    transform: rotate(45deg);
}

.arrow--sideDown {
    transform: rotate(-45deg);
}

.arrow--hoverLine {
    width: 12px;
    transform: scale(0);
}

.arrow,
.line {
    transition: transform 100ms ease-in;
}

.primary-btn {
    width: 100%;
    background: var(--lila);
    color: var(--white);
    border: none;
    border-radius: 20px;
    height: 2.6rem;
    text-transform: uppercase;
    padding: .1rem;
}

.primary-btn:hover {
    background: rgba(97, 10, 209, 0.65);
}

.btn:hover .arrow {
    transform: none;
}

.btn:hover .arrow--hoverLine {
    transform: scale(1);
}

.tip {
    width: 1.25rem;
    height: 1.25rem;
    background-color: #fff;
    position: absolute;
    top: 4.15rem;
    left: 0;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transition: transform 100ms linear;
    opacity: 0;
}

.header__expandMenu {
    position: absolute;
    top: 4.5rem;
    left: 50%;
    width: 95%;
    height: 90%;
    transform-origin: 0%;
    transform: translateX(-50%) rotate3d(1, 0, 0, -15deg);
    background-color: white;
    border-radius: 0.5rem;
    perspective: 100px;
    transition: width 250ms ease, height 250ms ease, opacity 150ms ease, transform 200ms ease-in;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
    border: 1px solid #eaeaea;
    border-top: none;
    border-left: none;
    border-right: none;
}

.menu__container {
    width: 100%;
    height: 100%;
    background-color: white;
}

.menu__container > * {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 2rem;
    overflow: hidden;
    transform: translateX(-35%);
    transition: transform 250ms linear, opacity 250ms ease;
    opacity: 0;
}

.menu-list {
    background: white;
    position: absolute;
    top: 3rem;
    display: none;
}

.menu-list.open {
    display: flex;
}

.item--one {
    grid-column: span 2;
}

.long-nav-word a {
    word-break: break-word;
}

.subMenu__items {
    list-style: none;
    margin-bottom: 0;
}

.title {
    text-transform: uppercase;
    font-size: 0.85rem;
    margin-bottom: 1.75rem;
}

.subMenu--item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: .5rem;
}

.subMenu__items .subMenu--item a{
    color: black;
    font-weight: normal;
    white-space: nowrap;
    font-size: 18px;
}

.subMenu__items .subMenu--item a:hover{
    color: red;
}

.icon-link {
    margin-right: 1rem;
}

.icon-link:first-of-type {
    cursor: default;
}

.label__container {
    margin-left: 0.75rem;
}

.label {
    font-size: 0.9rem;
    font-weight: 700;
    color: rgba(0, 0, 73, 0.644);
}

.icon--production {
    transform: translateY(-55%) scale(1.1);
}

.right--arrow {
    width: 12px;
    transform: translateY(1px) scale(0, 1);
    transition: transform 150ms ease-in-out;
}

.subMenu--item:hover .right--arrow {
    transform: translateY(1.75px) scale(1);
}

.prev {
    transform: translateX(-65%);
}

.expand {
    opacity: 1;
    pointer-events: all;
    transform: translateX(-50%) rotateX(0);
}

.hover {
    opacity: 0.5;
    border-bottom: 2px solid var(--primary);
}

.new--expand {
    transition: opacity 150ms ease, transform 150ms ease-in;
}

.first {
    transition: none;
}

.sec--menu .subMenu--item + .subMenu--item {
    margin-top: 0.5rem;
}

.sec__title {
    color: grey;
    margin-bottom: 0.65rem;
}

.sec__links {
    list-style: none;
    padding: 0 0 0.2rem 0;
}

.sec__links > li {
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 400;
}

.sec__links--container {
    padding-left: 2rem;
}

.more__info {
    grid-column: span 2;
}

.more__links {
    padding-left: 1.5rem;
}

.fs-15 {
    font-size: 15px;
    color: var(--black);
}

.fs-15:hover {
    color: var(--primary);
}

.or {
    font-size: 1rem;
}

li.text-muted:hover {
    opacity: 0.5;
}

.social-button {
    width: 50% !important;
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.5s;
}

.hover-opacity {
    transition: 0.5s;
}

.hover-opacity:hover {
    opacity: 0.75;
}

.responsive-payments-card {
    height: 100vh;
    width: 100%;
}

.active-inbox {
    border: 2px solid var(--primary) !important;
}

.input-group-text {
    background: var(--lila) !important;
    font-weight: 600 !important;
    color: var(--white) !important;
}

.input-primary {
    margin-top: 1rem;
    border-radius: 25px;
    width: 100%;
    height: 45px;
    border: 2px solid #dbdbdb;
    outline: none;
    padding: 0.25rem 0.5rem;
    background: var(--black);
    color: var(--white);
}

input.input-primary::placeholder {
    color: var(--white);
    opacity: .7;
}

@media (max-width: 1400px) {
    .user-location-responsive {
        width: 350px;
    }
}

@media (min-width: 1025px) and (max-width: 1400px) {
    .social-text {
        font-size: 12px !important;
    }
}

@media (max-width: 1024px) {
    .user-location-responsive {
        width: 500px;
    }
}

@media (max-width: 600px) {
    .user-location-responsive {
        width: 150px;
    }
}

@media (max-width: 768px) {
    .mt-6 {
        padding-top: 6rem;
    }

    .responsive-payments-card {
        height: unset;
        width: unset;
        margin: auto;
    }
}

.text-prim {
    color: var(--primary);
}

.notification-button {
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background: var(--primary);
    color: var(--white);
}

.or:before,
.or:after {
    display: inline-block;
    content: "";
    border-top: 0.1rem solid var(--black);
    width: 1rem;
    margin: 0 1rem;
    transform: translateY(-0.2rem);
}


.debit-card-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);
    color: var(--white);
    width: 100%;
    padding: 0.8rem 0.5rem;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 0.25rem;
    transition: 0.5s;
}

.debit-card-button:hover {
    opacity: 0.9;
}

.notification-icon {
    border-radius: 50%;
    background: var(--primary);
    font-weight: 600;
    color: var(--white);
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
}

.top-right-position {
    position: absolute;
    bottom: 40%;
    left: 40%;
}

.task-dropdown {
    background: var(--gray);
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    padding: 0 2rem 2rem 2rem;
    max-height: 300px;
    overflow: auto;
    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
    border: 1px solid #eaeaea;
}

.task-dropdown li {
    list-style: none;
}

.task-dropdown li a {
    border-bottom: 1px solid #dbdada;
    padding: 1rem 0;
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    word-break: break-all;
}

.bg-prim {
    background: var(--primary);
}

.dropbtn {
    background-color: var(--primary);
    color: white;
    padding: 16px;
    font-size: 16px;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
}

.dropdown-content .BookingOverview_active_filter_child__2jVdF {
    width: 100%;
    color: var(--white);
}

.dropbtn-unactive {
    background-color: var(--black);
    color: white;
    padding: 16px;
    font-size: 16px;
    width: fit-content;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content p:hover {
    opacity: 0.8;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.custom-margin {
    margin-top: 6rem;
}

.notification-header-number {
    position: absolute;
    background: var(--primary);
    color: var(--white);
    font-size: 14px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -20%;
    right: 0;
    z-index: 1;
}

.notification-menu-item {
    background: var(--primary);
    color: var(--white);
    font-size: 14px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin-left: .5rem;
}

.notification-header-number-icon {
    position: absolute;
    background: var(--primary);
    color: var(--white);
    font-size: 14px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -20%;
    left: -50%;
}

[type="datetime-local"]:after {
    background: no-repeat center url("calendar-date-fill.svg");
    content: "";
    width: 20px;
    aspect-ratio: 1/1;
}

ul {
    padding: 0 1rem !important;
}

.accordion-button:not(.collapsed) {
    color: var(--primary) !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button.collapsed,
.collapse {
    min-width: 200px;
}

.filter-blur {
    filter: blur(4px);
}

.css-g1d714-ValueContainer {
    height: -webkit-fill-available;
    overflow-y: auto !important;
}

.user-search-title:not(:first-child) {
    margin-left: 1rem;
}

.social-network-switch {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    transition: 0.3s;
}

.social-network-switch:hover {
    color: white;
    cursor: pointer;
    background: var(--primary);
}

.user-search-title.active {
    color: var(--primary);
}

.user-search-title:hover {
    cursor: pointer;
    color: var(--primary);
    text-decoration: underline;
}

.css-1nrlq1o-MuiFormControl-root {
    width: 7%;
}
.MuiOutlinedInput-input,
.MuiInputBase-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: transparent;
    
    height: 35px;
}

.country-flag {
    width: 25px;
    aspect-ratio: 1/1;
}

.option-flag:before {
    content: "";
}

/* Change the background color of the dropdown button when the dropdown content is shown */