@import "../../../components/Styles/Variables.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $black;
    padding-bottom: 5rem;
    height: 75%;

    & > * {
        color: $white;
    }

}
.centerText {
    text-align: center;
}
.textfield {
    margin-top: 50px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 20%;
    font-family: Roboto;
    .headline {
        font-family: bold;
        font-size: 30px;
        font-weight: bold;
        color: $white;
    }
    .bodyText {
        margin-top: 30px;
        font-size: 20px;
    }
}

@media (min-width: 0px) and (max-width: 800px) {
    .textfield{
        text-align: center;
    }
}