@import "../../../components/Styles/Variables";

.ProfileSection {

  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__head {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid $white;
    padding-bottom: 1.5rem;
    position: relative;

    &__edit-button {
      display: flex;
      align-items: center;
    }

    &__heading {
      display: flex;
      flex-direction: row;
    }

    &__image {
      height: 3rem;
      width: 3rem;
      margin-right: 2rem;

      svg {
        fill: $white;
      }
    }

    &__headline {
      text-transform: uppercase;
      color: white;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      letter-spacing: 3px;
    }

  }


  &__body {
    padding: 1rem 0;
    color: white;
  }
}