@import "../../../components/Styles/Variables.scss";

.section {
    padding-top: 5rem;
    width: 100%;
    position: relative;
    padding-bottom: 5rem;
    background: $black;

    .sectionHeadline {
        position: absolute;
        top:-5rem;
        left: 100px;
        font-family: HyperwaveOne;
        font-size: 200px;
        color: $white;
    }
    .artistsTopConatiner {
        height: 70vh;
        width: 100%;
        background: no-repeat url("../../../assets/Images/artistBG.jpg") center;
        background-size: cover;
        overflow: hidden;
        box-shadow: inset 0 -10rem 4rem -5rem  $black;

        .textfield {
            height: 100%;
            margin: 100px;
            display: flex;
            flex-direction: column;
            width: 35%;
            .headline {
                white-space: nowrap;
                font-family: bold;
                font-size: 40px;
                font-weight: bold;
                color: $white;
            }
            .bodyText {
                line-height: 45px;
                margin-top: 30px;
                font-family: Roboto;
                font-size: 30px;
                color: $white;
            }
            .button {
                margin-top: 40px;
            }
        }
    }
    .bottomContainer {
        position: relative;
        height: 40%;
        top:-10rem;
        margin-left: 100px;
        margin-right: 100px;
        display: flex;
        justify-content: center;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .section {
        .sectionHeadline {
            position: absolute;
            top: -50px;
            left: 30px;
            font-family: HyperwaveOne;
            font-size: 150px;
        }
        .bottomContainer {
            margin-left: 10px;
            margin-right: 10px;
            top: -10rem;
        }
        .artistsTopConatiner {

            height: 100vh;

          .textfield {
              width: 50%;
              margin: 5rem;
              padding: 2rem
          }
        }
    }
}
@media  (max-width: 370px) {
.section {
        .sectionHeadline {
            position: absolute;
            top: -150px;
            left: 0px;
            font-family: HyperwaveOne;
            font-size: 150px;
        }
    }
}

@media screen and (max-width: 768px) {
    .section {

        height: unset;
        .sectionHeadline {
            top: 0;
            font-size: 100px;
            left: 1rem;
            right: 1rem;
            text-align: left;
        }

        .artistsTopConatiner {
            height: 100%;
        }

        .bottomContainer {
            top: 0;
            margin: 0;
        }

        .textfield {
            width: 100% !important;
            padding: 1rem;
            margin: 3rem 0 0 0 !important;

            .headline, .bodyText {
                text-align: center;
                white-space: break-spaces !important;
            }

            .headline {
                font-size: 2rem !important;
            }

            .textfield {
                font-size: 1.5rem !important;
            }

            .button {
                display: flex;
                justify-content: center;
            }
        }
    }
}
