@import "./Variables";

.transition_card_title {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;

  &:hover {
    background-position: right center;
  }
}

.bg_secondary {
  height: 3.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: $lila;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card_title {
  color: $white;
  font-size: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  margin-bottom: 0;
  padding-left: 1rem;
}

.in_card_title {
  font-size: 18px;
  color: $white;
}

.text_primary {
  color: $primary;
}

.in_card_main_title {
  font-size: 25px;
  font-weight: 600;
  color: $white;
}

.review_scroll_box {
  max-height: 400px;
  overflow: auto;
}

.bg_gray {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding: 0.25rem;
  border-radius: 10px;
}

.bg_black {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin: 1rem;
  padding: 0.25rem;
}

.box_shadow {
  box-shadow: 20px 0px 25px -2px rgb(0, 0, 0);
  display: flex;
  flex: 1;
  margin: 1rem .5rem;
  width: 100%;
  overflow: hidden;
}

.border_left {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left: 5px solid $secondary;

  div {
    margin-left: 0.5rem;
  }
}
.biografieContainer {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-left: 5px solid $secondary;
  overflow-wrap: break-word;
  inline-size: 95%;
  div {
    margin-left: 0.5rem;
  }
}

.parent_biography {
  cursor: pointer;
  min-height: 160px;

  &:hover {
    .border_left {
      border-color: $primary;
    }

    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
    border: 1px solid #eaeaea;

    .btn_delete {
      display: inline-block !important;
    }
  }
}

.btn_delete {
  @extend .btn_primary;
  display: none;
  width: fit-content !important;
  background: $danger !important;
  height: fit-content !important;
  margin: 0 !important;
  margin-top: 1rem !important;

  &:hover {
    display: inline-block !important;
    background: rgba(223, 71, 89, 0.75) !important;
  }
}

.border_right {
  border-right: 1px solid #dee2e6 !important;
}

.input,
select {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
}

.fs_13 {
  font-size: 13px;
}

.responsive_input {
  width: 100%;
}

@media (min-width: 1200px) {
  .mt_xl_6 {
    margin-top: 6rem !important;
  }
  .responsive_input {
    width: 25%;
  }
}

@mixin button_styling {
  background: $lila;
  color: $white;
  font-weight: 700;
  border: none;
  height: 40px;
  border-radius: 20px;
  padding: 0 1rem;
}

.btn_primary_modal {
  @include button_styling;

  &:hover {
    opacity: .8;
  }
}

.bg_danger {
  background: $danger;
}

.btn_primary {
  @include button_styling;
  width: 100%;
  margin: auto;

  &:hover {
    background: rgba(91, 122, 218, 0.65);
  }
}

.bg_card {
  background: $white;
}

.user_comment_picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.border_radius {
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .border_right {
    border-bottom: 1px solid #dee2e6 !important;
    border-right: none !important;
  }
}

@media (max-width: 768px) {
  .card_title {
    font-size: 23px;
  }
}
