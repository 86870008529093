@import "../Styles/Variables";

.eStickyNote {
  position: absolute;
  background: $white;
  border-radius: 10px;
  color: black;
  padding: 1rem;
  display: none;
  max-width: 400px;
  z-Index: 2;

  @media screen and (max-width: 768px) {
    right: 1rem !important;
    left: 1rem;
  }
}

.open {
  composes: eStickyNote;
  display: block;
}

.closeBtn {
  position: absolute;
  height: 1.75rem;
  width: 1.75rem;
  top: -1rem;
  right: -1rem;
  display: flex;
  padding: 0.4rem;
  background: white;
  border: 1px solid black;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 1rem;
    width: 1rem;
    stroke: $black;
  }

}