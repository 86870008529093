@import "../../../components/Styles/Variables.scss";
.section {
    padding-top: 10rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 75%;
    background:$black;

    .textfield {
        width: 70%;
        text-align: center;
        .headline {
            font-family: bold;
            font-size: 30px;
            font-weight: bold;
            color: $white;
        }
        .bodyText {
            margin-top: 30px;
            font-size: 22px;
            font-family: Roboto;
            color: $white;
        }
    }

    @media screen and (max-width: 800px) {
        height: unset;
        padding-bottom: 10rem;
    }
}
