@import "./Variables";

.Booking {
  min-height: calc(100vh - 120px);
  background: $black;
}

.Payments {
  background: $black;
  height: 100%;

  @media screen and (max-width: 480px) {
    min-height: calc(100vh - 120px);
  }
}

.heading {
  height: 10rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  background: $lila;
}

.actionContainer {
  border-radius: 20px;
  border: 1px white solid;
  position: relative;
  width: 170px;
  height: 40px;
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
}

.ActionRow {
  cursor: pointer;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.input,
select {
  margin-top: 0.5rem;
  border-radius: 10px;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
}

.status_pending {
  font-weight: 700;
  color: $warning;
}

.status_accepted {
  font-weight: 700;
  color: $primary;
}

.status_declined {
  font-weight: 700;
  color: $danger;
}

.status_offered {
  font-weight: 700;
  color: $offered;
}

.status_opened {
  font-weight: 700;
  color: $green;
}

.status_finished {
  font-weight: 700;
  color: $finished;
}

.bg_secondary {
  height: 3.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: $white;
  font-size: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $lila;
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.filter_bookings {
  font-size: 25px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: $black;
}

.bg_black {
  background: $black;
  border-radius: 10px;
  box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
  border: 1px solid #eaeaea;
  margin-bottom: 1rem;
}

.card_radius {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bg_gray {
  background: $black;
  padding: 1rem 0;
  color: white;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.bg_table_content {
  padding: 1rem;
  color: $white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.table_price {
  padding: 1rem;
  color: black;
  font-size: 18px;
  font-weight: 600;
  text-align: right;
}

.btn_primary {
  background: $lila;
  color: $white;
  font-weight: 700;
  border: none;
  height: 45px;
  border-radius: 10px;
  width: 20%;

  &:hover {
    opacity: .9;
  }
}

.btn_primary_modal {
  @extend .btn_primary;
  width: auto;
  border-radius: 0.25rem;
  height: 40px;

  &:disabled {
    cursor: not-allowed;
  }
}

.active_tab {
  background-color: $primary;
  color: white;
  padding: 16px;
  width: fit-content;
  font-size: 16px;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.active_filter_child {
  @extend .active_tab;
  border-bottom: none;
}

.btn_decline {
  @extend .btn_primary;
  background: $danger;

  &:hover {
    background: rgba(223, 71, 89, 0.75);
  }
}

.btn_warning {
  @extend .btn_primary;
  background: $warning;

  &:hover {
    background: rgba(193, 193, 193, 0.75);
  }
}

.user_profile_picture {
  width: 85px;
  border-radius: 50%;
}

.user_profile_name {
  margin-left: 2rem;
  font-size: 25px;
  color: $secondary;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .header_title {
    font-size: 35px;
  }

  .bg_secondary {
    font-size: 20px !important;
  }

  .responsive_image {
    height: 6rem;
  }

  .btn_primary {
    margin: 0.5rem 0;
    width: 75%;
  }

  .filter_bookings {
    font-size: 20px;
  }
}

.responsive_image {
  margin-top: 5rem;
}

.bookingContent {
  width: 70%;
  padding: 1rem;
  margin: 1rem 0;
  overflow: scroll;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    min-height: 100vh;
  }
}

.bookingContent_inner {
  padding: 0rem 2rem;
}

.bookingContentContainer {
  display: flex;
  flex-direction: row;
  height: calc(100% - 120px - 5rem);
  width: 100%;
  margin-top: 1rem;
  position: relative;
  background: $black;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .tabLeft {
    position: relative;
    width: calc(30% - 2rem);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 2rem 1rem;

    @media screen and (max-width: 768px) {
      width: calc(100% - 2rem);
    }

    .tabEntryTop {
      height: 60px;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      color: $white;
      background: #6c7293;
      margin-top: 8px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .tabEntryDown {
      height: 40px;
      width: 100%;
      margin-top: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      color: white;
    }

    .activeTab {
      background: $lila;
      animation: gradient 15s ease infinite;
      color: #fff;
      background-size: 400% 400%;
      @media (min-width: 601px) {
        //position: sticky;
        //position: -webkit-sticky;
        top: 100px;
      }
    }
  }
}
