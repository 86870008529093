@import "../Styles/Variables";

.ProfileCard {
  padding: 1rem;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 0px $black;
  transition: transform 0.2s linear, box-shadow 0.2s linear;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 30px -5px black;
  }

  &__image-wrapper {
    width: 100%;

    &__image {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__favorite-btn{
    position: absolute;
    right: 2rem;
    top: 2rem;
    border: none;
    transition: transform 0.2s linear;
    background: none;

    &:hover {
      transform: scale(1.2);
    }

    &__img {
      height: 2rem;
      width: 2rem;
    }
  }

  &__info {
    margin-top: 1rem;
    flex: 1 1;

    &__text {
      word-break: break-word;
      padding-bottom: 1rem;
      text-transform: uppercase;
      color: $white;
      border-bottom: 2px solid $white;
      font-size: 20px;

      &.artistname {
        font-weight: bold;
      }
    }
  }

  .material-icons {
    height: 100%;

  }
}

.ProfileCard-link {
  text-decoration: none;
}