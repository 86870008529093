@import "../../../../components/Styles/Variables.scss";

.ImageSliderContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .switchButtonsMobile {
        display: none;
    }
    .switchButtons {
    }
}

.ImageSliderItemConatiner {
    -webkit-transition: width 0.3s, height 2s, transform 2s;
    -moz-transition: width 0.3s, height 2s, transform 2s;
    -o-transition: width 0.3s, height 2s, transform 2s;
    transition: width 0.3s, height 2s, transform 2s;
    height: 100%;
    width: 500px;
    display: flex;
    position: relative;
    flex-direction: row;
    margin-left: 20px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }

    .leftImageContainer {
        width: 45%;
        height: 100%;
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .rightImageContainer {
        width: 55%;
        background-color: $lila;
        color: $white;
        display: flex;
        flex-direction: column;
        padding: 30px;
        overflow: hidden;
        .headline {
            font-family: Roboto;
            font-size: 20px;
            font-weight: bold;
        }
        .body {
            margin-top: 20px;
            font-family: Roboto;
            font-size: 20px;
        }
    }
}

.ImageSliderItemConatinerActive {
    height: 100%;
    margin-left: 20px;
    width: 225px;
    display: flex;
    position: relative;
    flex-direction: row;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }

    @media (min-width: 481px) and (max-width: 769px) {
        display: none;
    }


    .leftImageContainer {
        width: 100%;
        height: 100%;
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

@media (min-width: 769px) and (max-width: 1400px) {
    .hide {
        display: none;
    }
    .ImageSliderContainer {
        .switchButtonsMobile {
            display: none;
        }
        .switchButtons {
        }
    }
}

@media (min-width: 481px) and (max-width: 768px) {

}

@media (min-width: 0px) and (max-width: 480px) {
    .ImageSliderContainer {
        flex-direction: column;
        .switchButtonsMobile {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
        .switchButtons {
            display: none;
        }
    }
    .ImageSliderItemConatiner {
        height: 100%;
        margin-left: 0;
        width: 100%;
        .leftImageContainer {
            width: 100%;

            .image {
                object-fit: contain;
            }
        }
        .rightImageContainer {
            padding: 20px;
            width: 100%;
            .headline {
                font-size: 18px;
            }
            .body {
                font-size: 18px;
            }
        }
    }
    .hideMobile {
        display: none;
    }
}
