@import "../../../components/Styles/Variables";

.header {
  height: 10rem;
  width: 100%;
  background: $lila;
  display: flex;
  align-items: center;
  margin-top: 5rem;

  .header_title {
    font-size: 50px;
    font-weight: bold;

    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }
  }
}

.info {
  margin: 1rem 0;
}

.info_wrapper {
  display: flex;
  flex: 1;
}
