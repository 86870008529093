@import "../../components/Styles/Variables.scss";
.footer {
    position: static;
    height: 120px;
    width: 100%;
    bottom: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url("BH-Splash-Footer-lang.svg");
    background-repeat: no-repeat;
    background-color: $black;
    background-size: 115%;
    background-position-x: 80%;
    background-position-y: 40%;


    @media screen and (max-width: 480px) {
        flex-direction: column;
        background-image: url("BH-Splash-Footer-mobile.svg");
        background-repeat: no-repeat;
        background-color: $black;
        background-size: 140%;
        background-position-x: 50%;
        background-position-y: 50%;
    }
}

.link {
    font-size: 22px;
    font-family: Roboto;

    & > a {
        text-decoration: none;
        color: $white;

        &:hover {
            text-decoration: underline;
        }
    }
}
