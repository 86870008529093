@import "../../../components/Styles/Variables.scss";
.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: $black;
    height: 100%;

    & > * {
        color: $white;
    }
}
.sectionHeadline {
    position: relative;
    font-family: HyperwaveOne;
    font-size: 200px;
    width: 90%;
}
.mobileContainer {
    display: none;
}
.container {
    height: 100%;
    width: 80%;
}
.row1 {
    height: fit-content;
    margin-bottom: 50px;
    width: 30%;
    .headline {
        font-family: bold;
        font-size: 25px;
        font-weight: bold;
        color: $white;
    }
    .bodyText {
        margin-top: 30px;
        font-family: Roboto;
        font-size: 20px;
    }
}
.row2 {
    height: 700px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    .center {
        position: relative;
        width: 40%;
        height: 100%;
        top: -10%;
        .purpleBox {
            background-color: $lila;
            height: 70%;
            width: 100%;
            margin-left: 40px;
            margin-top: -40px;
            z-index: -1;
        }
        .image {
            width: 100%;
            height: 90%;
            object-fit: cover;
            position: absolute;
        }
    }
    .left {
        width: 30%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .image {
            width: 100%;
            max-height: 50%;
            object-fit: contain;
        }
        .switchButton {
            position: absolute;
            top: 27%;
        }
        .moveToRight {
            left: calc(100% - 50px);
        }
    }
}

@media (max-width: 800px) {
    .section {
        height: unset;
        justify-content: unset;
        position: relative;
    }
    .sectionHeadline {
        position: relative;
        font-family: HyperwaveOne;
        font-size: 100px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .row2 {
        display: none;
    }
    .container {
        height: fit-content;
    }
    .mobileContainer {
        width: 100%;
        height: 40%;
        display: unset;
    }
    .row1 {
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .headline {
            font-family: bold;
            font-size: 25px;
            font-weight: bold;
            color: $white;
        }
        .bodyText {
            margin-top: 30px;
            font-family: Roboto;
            font-size: 20px;
        }
    }
}
