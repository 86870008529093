@import "../../../components/Styles/Variables";

.ProfileInformation {

  width: 100%;
  margin: 5rem 0;
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
    border-bottom: 2px solid $white;

    &__adornment {
      margin-bottom: 0;
      color: $white;
      opacity: .4;
    }

    .MuiTypography-root {
      padding-right: 14px;
      text-align: end;
    }
  }

  &__button-group {
    position: absolute;
    right: 0;
    top: -3rem;
    background: none;
    border: none;

    &__button {
      background: none;
      border: none;

      img {
        height: 2rem;
        width: 2rem;
      }

      & > svg {
        fill: $white;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  &__profile-infos {

    &__info {
      display: flex;
      align-items: center;
      border-bottom: 2px solid $white;
      text-transform: uppercase;
      min-height: 50px;
      color: $white;
      margin: 0;
      font-weight: bold;
      font-size: 1.25rem;

      &:first-of-type {
        border-top: 2px solid $white;
      }
    }
  }

  &__text-field {
    width: 100%;
    overflow: hidden;
    border-radius: 0 !important;

    &__select-adornment {
      margin-right: 1.5rem;
    }

    &__chip-container {
      display: flex;
      flex-wrap: wrap;

      &__deleteIcon.MuiChip-deleteIcon.MuiSvgIcon-root {
        fill: transparent;
      }

      &__deleteIcon.MuiChip-deleteIcon.MuiSvgIcon-root.show {
        fill: $white;
      }
    }

    .MuiTypography-root, &__placeholder {
      color: $white;
      opacity: .4;
      margin: 0;
    }

    .MuiInputAdornment-root {
      color: rgba(255,255,255,.4);
    }

    .MuiInputBase-root {
      height: 100%;
      padding: 0 14px 0 0;
    }

    .MuiInputBase-input {
      text-align: left;
      padding-left: 0;
      color: white;
      text-transform: uppercase;
    }

  }

  &__text-field-group {
    display: flex;
    position: relative;
    flex-direction: row;
    
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    &__line-space {
      height: 50px;
      border-bottom: 2px solid $white;

      &.mobile-space {
        display: none;
      }
      @media screen and (max-width: 768px) {
        &.mobile-space {
          display: block;
          border-bottom: none;
        }
      }
    }

    &__social-media {
      display: flex;
      align-items: center;
      min-height: 50px;
      border-bottom: 2px solid $white;
    }

    &__locationselect {
      width: 100%;
    }

    &__left {
      margin-right: .25rem;
    }

    &__right {
      margin-left: .25rem;
    }

    @media screen and (max-width: 768px) {
      &__left {
        margin-right: 0;
      }

      &__right {
        margin-left: 0;
      }
    }

  }

  &__disclaimer {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    color: $white;
    align-items: center;
    text-align: center;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiSvgIcon-root {
    fill: $white;
  }

  .MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: $white;
    opacity: .9;
  }

  .MuiOutlinedInput-input {
    height: unset;
    padding: 12px 0px;
  }

  .MuiChip-root {
    height: 24px;
  }

}