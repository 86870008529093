@import "./Variables";

.TransactionHistory {
  height: 100%;
  background: $black;
}

.header {
  font-size: 50px;
  color: $white;
  padding: 5rem;
  font-weight: 600;
  height: 40rem;
  margin-top: 5rem;
  box-shadow: inset 0 -10rem 4rem -5rem  $black;
  background: no-repeat url("../../assets/Images/artistBG.jpg") center;
  background-size: cover;
}

.headline {
  position: absolute;
  bottom: -5rem;
  font-family: HyperwaveOne;
  font-size: 12.5rem;

  @media screen and (max-width: 1200px) {
    left: 0;
    width: 100%;
    text-align: center;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    font-size: 7.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 5rem;
  }
}

.heading {
  height: 10rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  background: $lila;
}

.header_title {
  font-size: 50px;
  color: $white;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
}

.w_fit {
  width: fit-content;
}

.user_search_logo {
  width: 100%;
  z-index: -200;
  object-fit: cover;
  height: 300px;
  border-radius: 12px;
}

.status_pending {
  font-weight: 600;
  color: $warning;
}

.status_accepted {
  font-weight: 600;
  color: $primary;
}

.status_declined {
  font-weight: 600;
  color: $danger;
}

.status_offered {
  font-weight: 600;
  color: $offered;
}

.status_opened {
  font-weight: 600;
  color: $green;
}

.status_finished {
  font-weight: 600;
  color: $finished;
}

.card_body {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 3.5rem;
  background: linear-gradient(-45deg, $primary, #23a6d5, $secondary);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.xButton {
  background: $gray;
  border: none;
  font-weight: 700;
  color: $secondary;
  margin-left: 0.5rem;
  border-radius: 50%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;

  &:hover {
    background: $warning;
  }
}

option:disabled {
  color: gray;
}

select {
  color: gray;
}


option[selected] {
  color: $secondary !important;
}

.bg_white_card {
  display: flex;
  justify-content: center;
  margin: 1rem;
  padding: 0.25rem;
  background: $white;
}

.searchContainer {
  background: $black;
  padding: 5rem;

  @media screen and (max-width: 480px) {
    padding: 1rem;
  }
}

.card_username {
  font-weight: 600;
  font-size: 20px;
  text-overflow: ellipsis;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  color: $white;
}

.margin_bottom {
  margin-top: -2.7rem;
}

.bg_gray {
  background: $gray;
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding: 0.25rem;
  border-radius: 10px;
}

.btn_primary {
  width: 100%;
  background: $lila;
  color: $white;
  border: none;
  border-radius: 20px;
  height: 2.6rem;
  text-transform: uppercase;

  &:hover {
    background: rgba(91, 122, 218, 0.65);
  }
}

.locationSelect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.locationSelectField {
  width: 70%;
}

.additionalInfo {
  background: $black;

  .imageUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40vh;
    border-radius: 0.25rem;
    object-fit: cover;
    opacity: 1;

    &:hover {
      opacity: 0.7;
      cursor: pointer;

      h2 {
        color: $gray;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }
}

@media (max-width: 1399px) {
  .card_username {
    color: $white;
  }
  .input {
    width: 100% !important;
  }
}

.bg_black {
  box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
  background: $white;
  border: 1px solid #eaeaea;

  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding: 0.25rem;
}

.login_section_image {
  border-radius: 10px;
  width: 100%;
}

.nameContainer {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0px;

  border-radius: 12px;
  backdrop-filter: blur(10px);
  filter: brightness(1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_img {
  border: 3px solid $white;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 12px;
  object-fit: cover;
}

.title_text {
  font-size: 18px;
  color: $secondary;
}

.text {
  color: $secondary;
  font-size: 16px;
}

.text_primary {
  color: $primary;
  border-bottom: 2px solid $primary;
}

.active_tab {
  animation: ease-in-out;
  font-weight: 600;
  color: $primary;
  margin-left: 0.5rem;
  border-bottom: 3px solid $primary;
  cursor: pointer;
}

.icon {
  margin-left: -30px;
}

.inputPlaceholder {
  color: $white;
}

.input {
  border-radius: 20px !important;
  background: $black;
  width: 100%;
  height: 45px;
  border: 2px solid $white;
  outline: none;
  padding: 0.25rem 0.5rem;

  &:disabled {
    background: $gray;
    cursor: not-allowed;
  }
}

.input > input {

  &::-webkit-input-placeholder {
    color: white;
  }

}

.input > input::placeholder {
  color: white !important;
}

.modalInput {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
  position: relative;
}

.nav_link {
  color: $primary !important;
  background-color: transparent !important;
  border-bottom: 1px solid $primary !important;

  &.active {
    color: $primary !important;
    background-color: transparent !important;
    border-bottom: 1px solid $primary !important;
  }
}

.yes_button {
  @extend .btn_primary;
  display: flex;
  align-items: center;
  width: auto;
  margin: 0;
}

.tickets_table {
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment_section {
  max-height: 15rem;
  padding-top: 0.5rem;
  overflow-x: hidden;
}

.eventReviewCardImage {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .headerTitle {
    font-size: 2.5rem;
  }

  .user_search_logo {
    height: 200px;
  }
}

.company_logo {
  width: fit-content;
  position: relative;

  .user_profile_picture {
    width: 250px;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  &:hover .user_profile_picture {
    opacity: 0.3;
  }

  .photoIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &:hover .photoIcon {
    opacity: 1;
  }

  @media screen and (max-width: 768px){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.pageSelectLabel {
  color: $white;
}

.paginationSelect {
  background: $black;
  border-radius: 25px;
  color: $white;
}
