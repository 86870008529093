@import "./Variables";

.ProfileCalendar {
  margin-bottom: 2rem;

  @media screen and (max-width: 480px) {
    margin-bottom: 0;
  }
}

.calendarIcon {
  display: flex;
  justify-content: end;
  height: 100%;
  align-items: center;

  img {
    height: 5rem;
    width: 5rem;
  }
}

.bg_secondary {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid $white;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.card_title {
  color: $white;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  letter-spacing: 3px;
}

.calendar_icon {
  height: 3rem;
  margin-right: 2rem;
}

.bg_black {
  padding: 2rem 0.7rem;
  box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
  margin: auto;
}


.btn_primary {
  background: $primary;
  color: $white;
  font-weight: 700;
  border: none;
  height: auto;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  width: fit-content;

  &:hover {
    background: rgba(91, 122, 218, 0.65);
  }
}

.btn_warning {
  @extend .btn_primary;
  background: $warning;

  &:hover {
    background: rgba(193, 193, 193, 0.65);
  }
}

.btn_apply {
  @extend .btn_primary;
  background: $green;

  &:hover {
    background: rgba(102, 198, 10, 0.75);
  }
}

.eventReviewCardImage {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.btn_decline {
  @extend .btn_primary;
  background: $danger;

  &:hover {
    background: rgba(223, 71, 89, 0.75);
  }
}


@media(max-width: 600px) {
  .bg_black {
    padding: unset;
  }
}