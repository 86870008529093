@import '../../components/Styles/Variables.scss';
.registerButtonLila {
    background-color: $lila;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: fit-content;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 60px;
    cursor: pointer;
    border: none;
    text-decoration: none;
}
.registerButtonLila:hover {
    background-color: $white;
    color: $lila;
}
.registerButtonWhite {
    background-color: $white;
    color: $lila;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: fit-content;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 60px;
    cursor: pointer;
    text-decoration: none;
}
.registerButtonWhite:hover {
    background-color: $lila;
    color: $white;
    border: 2px solid $white
}

