@import "../../../components/Styles/Variables.scss";

.VRSection {
  display: flex;
  justify-content: center;
  padding: 0 5rem;
  position: relative;
  background: $black;
  height: 100%;

  @media screen and (max-width: 480px) {
    padding: 2rem;
    height: unset;
  }

  @media screen and (max-width: 1024px)  and (min-width: 769px) {
    padding: 5rem;
    height: unset;
  }

  &__header {
    z-index: 2;
    width: 100%;
    position: absolute;
    top: -7rem;
    padding: 0 4rem;

    @media screen and (max-width: 480px) {
      top: -2rem;
    }

    @media screen and (max-width: 768px) and (min-width: 481px) {
      top: -5rem;
    }

    @media screen and (max-width: 1024px)  and (min-width: 769px) {
      top: 0rem;
    }

    &__headline {

      @media screen and (max-width: 480px) {
        font-size: 50px;
        top: -1rem;
      }

      @media screen and (max-width: 768px)  and (min-width: 481px){
        font-size: 75px;
        top: -5rem;
      }

      @media screen and (max-width: 1024px)  and (min-width: 769px) {
        font-size: 100px;
      }
    }
  }

  &__sectionbox {
    height: 50%;
    display: flex;
    background: linear-gradient(90deg, rgba(255, 163, 0, 1) 0%, rgba(255, 64, 0, 1) 64%, rgba(209, 0, 255, 1) 100%);

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      height: 100%;
      align-items: center;
    }

    &__image-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

    }

    &__img {
      width: 480px;
      height: 500px;
      transform: scale3d(1.1, 1.1, 1);

      @media screen and (max-width: 940px) {
        transform: unset;
      }

      @media screen and (max-width: 1240px) {
        width: 400px;
        height: 500px;
      }

      @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 380px;
        height: 480px;
        transform: unset;
      }

      @media screen and (max-width: 480px) {
        transform: unset;
        width: 100%;
        height: 400px;
      }
    }

    &__text-body {
      width: 50%;
      padding: 3rem 3rem 3rem 0;
      color: $white;
      @media screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem 1rem;
        align-items: center;
      }
    }

    &__headline {
      font-weight: bold;
      letter-spacing: 2px;
      font-size: 2.5rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
      @media screen and (max-width: 1240px) {
        font-size: 2rem;
      }
    }

    &__text {
      font-size: 18px;
      @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
  }

}