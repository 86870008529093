@import "../Styles/Variables";

.InstagramGallery {
  display: flex;

  .instagram-gallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;

    .instagram-item {

      flex: 1 0 25%;
      margin-bottom: 2rem;

      .instagram-link {
        .instagram-image {
          height: 200px;
          width: 200px;
          object-fit: cover;
        }
      }
    }
  }


}