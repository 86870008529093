@import "./Variables";

.login_section {
  background: $gray;
  height: 100vh;
  display: flex;
  align-items: center;

  .login_section_image {
    border-radius: 10px;
    width: 450px;
    height: 100%;
  }

  .black_line {
    height: 100%;
    background: $secondary;
    border-radius: 10px;
    width: 4px;
  }

  .card {
    display: flex;
    justify-content: center;
    border: 1px solid #eaeaea;

    box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);

    .border_radius {
      background: $white;
      border-radius: 10px;
    }

    .bg_secondary {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: linear-gradient(-45deg, $primary, #23a6d5, $secondary);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    .bg_black {
      background: $white;
    }

    input {
      border-radius: 10px;
      width: 100%;
      height: 45px;
      border: 2px solid #dbdbdb;
      outline: none;
      padding: 0.25rem 0.5rem;
    }

    .btn_primary {
      background: $primary;
      color: $white;
      font-weight: 700;
      border: none;
      height: 45px;
      border-radius: 10px;
      width: 95%;
      margin: auto;

      &:hover {
        background: rgba(91, 122, 218, 0.65);
      }
    }

    @mixin line_styling {
      content: "";
      display: block;
      width: 20%;
      height: 2px;
      background: $gray;
      top: 30%;
      position: absolute;
    }

  }

  .text_primary {
    color: $primary;
  }

  .bg_primary {
    color: $primary;
    background: $primary;
    width: 45%;
  }

  .border_primary {
    border: 1px solid $primary;
  }

  .w_45 {
    width: 45%;
  }
}

.cursor_pointer {
  width: 100%;
  cursor: pointer;
}

.btn_primary_modals {
  background: $lila;
  color: $white;
  border: 2px solid white;
  height: 40px;
  border-radius: 20px;
  width: auto;

  &:hover {
    transition: background .2s linear, color .2s linear;
    background: $white;
    color: $lila;
    border: 2px solid rgba(255,255,255,1);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    background: rgba(91, 122, 218, 0.65);
    border-color: $primary;
    box-shadow: 0 0 0 0.25rem $primary;
  }
}

.btn_secondary {
  background: #6c757d;
  border-radius: 20px;
  color: white;
  height: 40px;
  padding: 6px 12px;
  border: 2px solid #6c757d;

  &:hover {
    background: rgba(108,117,125,0.9);
  }
}

.bg_danger {
  background: $danger;
  border: none;

  &:hover {
    background: $white;
    border: none;
    color: $danger;
  }
}

.bg_warning {
  background: $warning;
  border: none;

  &:hover {
    background: $white;
    border: none;
    color: gray;
  }
}

@media (max-width: 768px) {
  .cursor_pointer {
    width: 70%;
  }
}


