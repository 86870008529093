@import "src/components/Styles/Variables";

.ProfileHeader {

  margin-top: -100px;
  display: flex;

  &__wrapper {
    flex-direction: row;
    align-items: end;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__profile-image {

    position: relative;
    margin-right: 2rem;

    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    &__image {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      opacity: 1;
      display: block;
      transition: 0.5s ease;
      backface-visibility: hidden;
      object-fit: cover;
    }
  }

  &__profile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__profile-info {

    &__name {
      font-weight: bold;
      font-size: 2rem;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 0;

      @media screen and (max-width: 768px) {
        text-align: center;
        line-break: anywhere;

        &__type {
          display: inline-block;
        }
      }
    }

    &__jobs {
      color: $white;
      font-weight: bold;
      font-size: 1.5rem;

      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }

}