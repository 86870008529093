@import "./Variables";

.loader {
  line-height: 50px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  font-weight: 900;
  color: $primary;
  letter-spacing: 0.2em;
  // z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .loaderDiv {
    position: relative;

  }

  .loaderDiv::before, .loaderDiv::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: $primary;
    position: absolute;
    animation: load .7s infinite alternate ease-in-out;
  }

  .loaderDiv::before {
    top: 0;
  }

  .loaderDiv::after {
    bottom: 0;
  }
}

@keyframes load {
  0% {
    left: -13%;
    height: 30px;
    width: 15px
  }
  50% {
    height: 8px;
    width: 40px
  }
  100% {
    left: 100%;
    height: 30px;
    width: 15px
  }
}
