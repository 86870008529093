$primary: #5b7ada;
$secondary-300: #461399;
$secondary-300: #653da6;
$secondary-600: #4b258a;
$secondary: #461399;
$lila: rgb(97, 10, 209);
$gray: #f5f5f5;
$white: #fff;
$danger: #b0010f;
$warning: #c1c1c1;
$offered: #421776;
$green: #66c60a;
$finished: #3b7200ff;
$success: #00d25b;
$orange: #ffb428;
$black: rgb(16, 15, 15);
$texthover: rgb(196, 196, 196);
