@import "../../components/Styles/Variables.scss";

.modalContainer {
    height: 100vh;
    width: 100vw;
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100000000000000;
}

.open {
    composes: modalContainer;
    display: flex;
}

.modalInnerContainer {
    min-height: 30vh;
    max-height: calc(100vh - 2rem);
    width: 50vw;
    padding: 1rem;
    background-color: $lila;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 480px) {
        width: calc(100% - 2rem);
    }
    @media screen and (min-width: 481px) and (max-width: 768px){
        width: calc(100% - 4rem);
    }
    @media screen and (min-width: 769px) and (max-width: 1024px){
        width: 75%;
    }
}

.closeButton {
    position: absolute;
    height: 30px;
    width: 30px;
    display: flex;
    color: #000;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 60px;
    background-color: $white;
    right: -15px;
    top: -15px;
    cursor: pointer;
}

.headline {
    color: #fff;
    font-size: 25px;
    font-weight: bold;
}

.textfields {
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 2rem 0 1rem 0;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.button {
    // background: linear-gradient(120deg, rgb(160, 43, 100), rgb(205, 58, 48));
    background: #FF0000FF; ;
    height: 40px;
    min-width: 30%;
    padding: 0 1rem;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $white;
    border: none;
    margin-bottom: .5rem;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.typography {
    color: $white;
}

.forgotPassword, .link  {

    color: $white !important;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        opacity: .95;
    }
}

.forgotPassword {
    margin-top: 1rem;
}

.input {
    border-radius: 30px;
    width: 100%;
    height: 45px;
    border: 2px solid #dbdbdb;
    background-color: transparent;
    outline: none;
    padding: 0.25rem 0.5rem;
    position: relative;
    color: $white;
}

.registrationInput {
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 2rem;
    }
}

.googleLoginBtn {
    margin-top: 0.5rem;
}

.input:first-of-type{
    margin-bottom: 1rem;
}

.redButton {
    background: #FF0000FF;
    color: white;
    text-transform: uppercase;
    border-radius: 30px;
    width: 100%;
    height: 2.5rem;
    border: none;
}

.blueButton {
    background: $lila;
    color: white;
    text-transform: uppercase;
    border-radius: 30px;
    width: 100%;
    height: 2.5rem;
    border: 2px solid $white;
}

.blueButton, .redButton {
    @media screen and (max-width: 480px) {
        &:first-of-type {

        }
    }
}

.buttonWrapper {
    width: 100%;
    display: flex;
    margin-bottom: 2rem;
}

.registrationButton {
    &:first-of-type {
        margin-right: .5rem;
    }
    &:last-of-type {
        margin-left: .5rem;
    }
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
}
input:focus::placeholder {
    color: transparent;
}

.warningMsg {
    color: red;
    width: 100%;
    text-align: center;
    padding: .5rem;
    white-space: pre-line;
    border-radius: 5px;
    margin-bottom: 1rem;
}
