@import "../../../components/Styles/Variables.scss";
.uspSection {
    display: flex;
    flex-direction: column;
    position: relative;
    background: $black;
    padding-bottom: 5rem;

    .footer {
        width: 100%;
        display: flex;

        justify-content: center;
        cursor: pointer;
    }

    .uspContainer {
        min-height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: row;

        .leftSideContainer {
            width: 30%;
            height: 100%;
            z-index: 1;

            .textfield {
                margin: 100px;
                display: flex;
                flex-direction: column;
                .headline {
                    white-space: nowrap;
                    font-family: bold;
                    font-size: 35px;
                    font-weight: bold;
                    color: $white;
                }
                .bodyText {
                    white-space: nowrap;
                    margin-top: 30px;
                    font-family: Roboto;
                    font-size: 22px;
                    font-weight: bold;
                    color: $white;
                }
            }
        }

        .rightSideContainer {
            width: 70%;
            height: fit-content;
            z-index: 1;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            margin-top: 100px;

            .ele {
                width: fit-content;
                display: flex;
                font-family: Roboto;
                letter-spacing: 5px;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                font-size: 20px;
                padding: .75rem;

                & > * {
                    color: white;
                }

                .eleIMG {
                    height: 75px;
                }
            }
            .ele:hover {
                background-color: $lila;
            }
            .row1 {
                width: 70%;
                height: 250px;
                display: flex;
                justify-content: space-around;
                align-items: flex-end;
                align-items: center;
            }
            .row2 {
                width: 100%;
                height: 250px;
                display: flex;
                align-items: flex-start;
                justify-content: space-around;
                align-items: center;
            }
        }
    }
}

@media (max-width: 1024px) and (min-width: 769px) {
    .uspSection {
        .uspContainer {
            .rightSideContainer {
                margin-top: 200px;
                .row1 {
                    height: unset;
                }
                .row2 {
                    margin-top: 50px;
                }
            }
            .leftSideContainer {
                .textfield {
                    margin:0;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .uspSection {
        position: relative;
        height: unset;
        .uspContainer {
            height: fit-content;
        }
        .footer {
            position: relative;
            height: fit-content;
            margin-top: 20px;
        }
        .uspContainer {
            flex-direction: column;
            .leftSideContainer {
                width: 100%;
                .textfield {
                    margin: 10px;
                    .headline {
                        font-size: 25px;
                        white-space: unset;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        text-align: center
                    }
                    .bodyText {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }
            .rightSideContainer {
                width: 100%;
                height: fit-content;
                margin-top: 0px;
                padding-right: 10px;
                padding-left: 10px;
                .row1 {
                    width: 100%;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    height: unset;
                }
                .row2 {
                    height: 66%;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-around;
                    width: 100%;

                    .ele {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .uspSection {
        position: relative;
        padding-top: 5rem;
        .uspContainer {
            .leftSideContainer {
                .textfield {
                    .headline {
                        text-align: center;
                    }
                    .bodyText {
                        text-align: center;
                    }
                }
            }
            .rightSideContainer {
                height: fit-content;
                margin-top: 1rem;

                .ele {
                    margin-bottom: 1rem;
                }

                .row2 {
                    &:first-of-type {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}
