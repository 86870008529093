@import './Variables';

.pt_6 {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

.darkCard {
  border-radius: 0.25rem;
  background: $white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 20px 0px 25px -2px rgba(0, 0, 0, 0.09);
  border: 1px solid #eaeaea;
  min-height: 10rem;

  .wrap_text {
    text-align: start;
    text-overflow: ellipsis;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
  }

  .cardTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: black;

    .percentText_success {
      font-weight: 600;
      color: $green;
      margin-left: 1rem;
      font-size: 1rem;
    }

    .percentText_danger {
      font-weight: 600;
      color: $danger;
      margin-left: 1rem;
      font-size: 1rem;
    }
  }

  .infoButton {
    background: $success;
    color: $white;
    font-weight: 600;
    border: none;
    padding: 0.25rem;
    min-width: 100px;

    &:hover {
      background: rgba(0, 210, 91, 0.75);
    }
  }

  .cardSubTitle {
    color: #6c7293;
  }

}

.input {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;

  &:disabled {
    background: $gray;
    cursor: not-allowed;
  }
}

.eventReviewCardImage {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.comment_section {
  max-height: 15rem;
  padding-top: 0.5rem;
  overflow-x: hidden;
}

.btn_primary {
  background: $primary;
  color: $white;
  font-weight: 700;
  border: none;
  height: 40px;
  border-radius: 5px;
  width: auto;

  &:hover {
    background: rgba(91, 122, 218, 0.65);
  }
}

.status_finished {
  font-weight: 600;
  color: $primary;
}

.status_declined {
  font-weight: 600;
  color: $danger;
}

.status_opened {
  font-weight: 600;
  color: $green;
}

.status_inProgress {
  font-weight: 600;
  color: $finished;
}

.input {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
}


.status_requested {
  font-weight: 600;
  color: $offered;
}

.btn_decline {
  @extend .btn_primary;
  background: $danger;

  &:hover {
    background: rgba(223, 71, 89, 0.75);
  }
}

.btn_warning {
  @extend .btn_primary;
  background: $warning;

  &:hover {
    background: rgba(193, 193, 193, 0.75);
  }
}

.btn_success {
  @extend .btn_primary;
  background: $success;

  &:hover {
    background: rgba(0, 210, 91, 0.75);
  }
}


@media (max-width: 768px) {
  .darkCard {
    margin-top: 1rem;
  }

  .pt_6 {
    padding-top: 5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .darkCard {
    flex-direction: column;
    text-align: center;
  }
}