@import "./src/components/Styles/Variables";

.Profile {
  background: $black;
  min-height: 100%;

  &__header-image {
    font-size: 50px;
    color: $white;
    padding: 2rem;
    font-weight: 600;
    height: 30rem;
    margin-top: 5rem;
    box-shadow: inset 0 -10rem 4rem -5rem  $black;
    background-size: cover !important;

    &__button-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .ProfileInformation__button-group__button {
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 56px;
      display: flex;
      height: 3rem;
      justify-content: center;
      width: 3rem;
    }
  }
}