@import "../../../components/Styles/Variables.scss";
.aboutSection {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: $black;
    overflow: hidden;

    .headline {
        font-family: HyperwaveOne;
        font-size: 200px;
        position: absolute;
        top: calc(-25% + 200px);
        left: 60px;
        color: #fff;
        z-index: 2;
        scroll-margin-top: 10rem;
    }

    .lilaBox {
        height: 50%;
        width: 90%;
        background-color: $lila;
        display: flex;
        flex-direction: row;
        .imageLeftContainer {
            width: 35%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            .imageAbout {
                position: relative;
                height: 140%;
                top: -15%;
            }
        }

        .imageRightContainer {
            width: 65%;
            height: 100%;

            .textfield {
                margin: 60px;

                .subtitle {
                    font-family: bold;
                    font-size: 35px;
                    font-weight: bold;
                    color: $white;
                }
                .description_title {
                    font-family: Roboto;
                    font-size: 22px;
                    font-weight: bold;
                    color: $white;
                }

                .buttonAbout {
                    background-color: $white;
                    color: $lila;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: fit-content;
                    padding-right: 40px;
                    padding-left: 40px;
                    border-radius: 60px;
                    cursor: pointer;
                }
                .buttonAbout:hover {
                    background-color: $lila;
                    color: $white;
                    border-width: 1px;
                    border-style: solid;
                    border-color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: fit-content;
                    padding-right: 40px;
                    padding-left: 40px;
                    border-radius: 60px;
                    cursor: pointer;
                }
            }
        }
    }

    @media (min-width: 800px) and (max-width: 1400px) {
        .lilaBox {
            height: 70%;
            display: flex;
            flex-direction: row;
            .imageLeftContainer {
                width: 35%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                .imageAbout {
                    position: relative;
                    height: 120%;
                    top: -5%;
                }
            }
        }
    }
    @media (max-width: 799px) {
        .lilaBox {
            height: 90%;
            display: flex;
            flex-direction: column;
            background-color: $lila;
            margin-top: 200px;
            .imageLeftContainer {
                width: 100%;
                height: 50%;
                display: flex;

                justify-content: center;
                .imageAbout {
                    position: relative;
                    height: 100%;
                    top: -5%;
                }
            }
            .imageRightContainer {
                width: 100%;
                height: 50%;
                .textfield {
                    margin: 30px;
                    .subtitle {
                        font-size: 25px;
                    }
                    .description_title {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 799px) {
    .aboutSection {
        height: 120vh;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 100px;
        .headline {
            font-family: HyperwaveOne;
            position: absolute;
            top: 0px;
            left: 20px;
            font-size: 100px;
            color: #fff;
            z-index: 1;
        }
    }
}
