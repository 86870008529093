@import "./Variables";

.header {
  margin-top: 5rem;
  height: 200px;
  width: 100%;
  background-position: center;
  object-fit: cover;
  background-size: cover;
}

.user_profile_picture {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 5px solid white;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.user_profile_name {
  font-weight: 600;
  font-size: 45px;
  color: $white;
  text-transform: uppercase;
}

.user_profile_about {
  color: $secondary;
  font-size: 18px;
}

.w_fit {
  width: fit-content;
  position: relative;

  &:hover .user_profile_picture {
    opacity: 0.3;
  }

  &:hover .middle {
    opacity: 1;
  }
}

.not_my_profile {
  width: fit-content;
  position: relative;
}

.user_profile_margin {
  margin-top: -100px;
  align-items: center;
}

.user_profile_rating {
  display: flex;
  margin-left: 1rem;
}

.input {
  border-radius: 10px;
  width: 100%;
  height: 45px;
  border: 2px solid #dbdbdb;
  outline: none;
  padding: 0.25rem 0.5rem;
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    inset: 0;
    height: 100%;
    border: 1px solid red;
    opacity: 0;
    width: 100%;
  }
}

.inputBlack {

  width: 100%;
  height: 4rem;
  border: 2px solid $white;
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  background: $black;
  color: $white;

}

.user_profile_rating_value {
  margin-left: 1rem;
  color: #c1c1c1;
}

.user_profile_activity {
  width: 45px;
  background: #66c60a;
  height: 45px;
  border-radius: 50%;
  position: absolute;
  bottom: 10%;
  right: 10%;
  border: 5px solid $white;
}

.btn_primary {
  background: $primary;
  color: $white;
  font-weight: 700;
  border: none;
  height: 40px;
  border-radius: 5px;
  width: 30%;

  &:hover {
    background: rgba(91, 122, 218, 0.65);
  }
}

.btn_primary_small {
  @extend .btn_primary;
  height: fit-content;
  width: auto;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin: 0 0 0 0.5rem;
}

@media (max-width: 1024px) {
  .user_profile_name {
    color: black;
  }
}

@media (max-width: 768px) {
  .btn_primary_small {
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 1400px) {
  .user_profile_name {
    font-size: 38px;
  }
}

@media (max-width: 1200px) {
  .user_profile_name {
    word-break: break-all;
    font-size: 30px;
    color: black;
  }
}
